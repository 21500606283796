import * as THREE from 'three';
import { OrbitControls } from 'three/examples/jsm/controls/OrbitControls.js';
import { GLTFLoader } from 'three/examples/jsm/loaders/GLTFLoader.js';
import { PositionalAudioHelper } from 'three/examples/jsm/helpers/PositionalAudioHelper.js';

// Soundball Configuration
const soundballConfigs = [
    
    {
        name: 'soundball1',
        position: new THREE.Vector3(-2.6, -1.3, -0.5),
        material: {
            color: Math.random() * 0xeef4fb,
            wireframe: false
        },
        soundPath: 'S/For_Motry_bsn_t1_5s.wav',
        hasModel: true,
        modelPath: 'models/model1.glb'
    },
    {
        name: 'soundball2',
        position: new THREE.Vector3(-2.95, 0.57, 0.83),

        material: {
            color: Math.random() * 0xe0ecf8,
            wireframe: false
        },
        soundPath: 'S/For_Motry_bsn_t2_5s.wav',
        hasModel: true,
        modelPath: 'models/model2.glb'
    },
    {
        name: 'soundball3',
        position: new THREE.Vector3(-2.87, 0.88, -0.22),

        material: {
            color: Math.random() * 0xd3e4f5,
            wireframe: false
        },
        soundPath: 'S/For_Motry_bsn_t3_5s.wav'
    },
    {
        name: 'soundball4',
        position: new THREE.Vector3(-2.30, 1.92, 0.43),

        material: {
            color: Math.random() * 0xc5dcf2,
            wireframe: false
        },
        soundPath: 'S/For_Motry_bsn_t4_5s.wav'
    },
    {
        name: 'soundball5',
        position: new THREE.Vector3(-1.28, 2.71, -0.11),

        material: {
            color: Math.random() * 0xb8d4f0,
            wireframe: false
        },
        soundPath: 'S/For_Motry_fl_t1_5s.wav'
    },
    {
        name: 'soundball6',
        position: new THREE.Vector3(-0.80, 2.89, 0.35),

        material: {
            color: Math.random() * 0xaacbed,

            wireframe: false
        },
        soundPath: 'S/For_Motry_fl_t2_5s.wav'
    },
    {
        name: 'soundball7',
        position: new THREE.Vector3(2.33, -1.88, 0.39),

        material: {
            // color: 0x9dc3ea,
            color: Math.random() * 0xFF0000,

            wireframe: false
        },
        soundPath: 'S/For_Motry_fl_t3_5s.wav'
    },
    {
        name: 'soundball8',
        position: new THREE.Vector3(2.26, -1.97, 0.92),
        material: {
            // color: 0xFF0000,
            color: Math.random() * 0x8fbbe7,
            wireframe: false
        },
        soundPath: 'S/For_Motry_fl_t4_5s.wav'
    },
    {
        name: 'soundball9',
        position: new THREE.Vector3(-1.85, -2.36, -0.62),
        material: {
            // color: 0x82b3e4,
            color: Math.random() * 0xFF0000,
            wireframe: false
        },
        soundPath: 'S/For_Motry_perc_t1_5s.wav'
    },
    {
        name: 'soundball10',
        position: new THREE.Vector3(2.95, 0.57, 0.98),
        material: {
            color: Math.random() * 0x74abe2,
            wireframe: false
        },
        soundPath: 'S/For_Motry_perc_t2_10s.wav'
    },
    {
        name: 'soundball11',
        position: new THREE.Vector3(-0.83, -2.88, 0.92),
        material: {
            color: Math.random() * 0x67a3df,
            wireframe: false
        },
        soundPath: 'S/For_Motry_perc_t3_10s.wav'
    },
    {
        name: 'soundball12',
        position: new THREE.Vector3(2.25, -1.98, 0.36),
        material: {
            color: Math.random() * 0x599bdc,
            wireframe: false
        },
        soundPath: 'S/For_Motry_perc_t4_5s.wav'
    },
    {
        name: 'soundball13',
        position: new THREE.Vector3(-1.96, -2.27, -0.88),
        material: {
            color: Math.random() * 0x4c92d9,
            wireframe: false
        },
        soundPath: 'S/For_Motry_va_t1_5s.wav'
    },
    {
        name: 'soundball14',
        position: new THREE.Vector3(1.19, 2.75, 0.49),
        material: {
            color: Math.random() * 0x3e8ad6,
            wireframe: false
        },
        soundPath: 'S/For_Motry_va_t2_5s.wav'
    },
    {
        name: 'soundball15',
        position: new THREE.Vector3(2.52, 1.63, 0.74),
        material: {
            color: Math.random() * 0x3182d3,
            wireframe: false
        },
        soundPath: 'S/For_Motry_va_t3_5s.wav',
    },
    {
        name: 'soundball16',
        position: new THREE.Vector3(2.75, 1.40, 0.70),
        material: {
            color: Math.random() * 0x3182d3,
            wireframe: false
        },
        soundPath: 'S/For_Motry_va_t4_5s.wav',
        hasModel: true,
        modelPath: 'models/model15.glb'
    }
    
    
];

const canvas = document.querySelector('canvas.webgl');
const scene = new THREE.Scene();
let isAudioContextInitialized = false;

window.addEventListener('click', () => {
    if (!isAudioContextInitialized) {
        const audioContext = THREE.AudioContext.getContext();
        if (audioContext.state === 'suspended') audioContext.resume().then(() => console.log('AudioContext resumed'));
        isAudioContextInitialized = true;
    }
});

// Camera setup
const camera = new THREE.PerspectiveCamera(75, window.innerWidth / window.innerHeight, 0.1, 100);
scene.add(camera);

const listener = new THREE.AudioListener();
camera.add(listener);

const audioLoader = new THREE.AudioLoader();
const sounds = [];
const models = [];
const analyzers = [];
const loader = new GLTFLoader();

const centerPosition = new THREE.Vector3(0, 0, 0);

const frustum = new THREE.Frustum();
const matrix = new THREE.Matrix4();


const backgroundSound = new THREE.PositionalAudio(listener);
let delayTime = 5000;  
let delayIncrement = 5000; 

const blackScreen = document.getElementById('blackScreen');

audioLoader.load('audio/1.mp3', (buffer) => {
    backgroundSound.setBuffer(buffer);
    backgroundSound.setVolume(0.5);

    function playBackgroundSound() {
        blackScreen.style.display = 'block';

        backgroundSound.play();
        console.log(`Background sound started with ${delayTime/1000} seconds delay`);

        const soundDuration = buffer.duration * 1000;

        setTimeout(() => {
            blackScreen.style.display = 'none';
            
            setTimeout(playBackgroundSound, delayTime);
            
            delayTime += delayIncrement;
        }, soundDuration);
    }

    playBackgroundSound();
});

camera.add(backgroundSound);



function createParticlesFromGeometry(geometry, color) {
    const particlesMaterial = new THREE.PointsMaterial({
        size: 0.05,
        color: color,
        transparent: true,
        opacity: 0.9,
        depthWrite: false
    });

    const particles = new THREE.Points(geometry, particlesMaterial);
    return particles;
}

function checkIfModelIsVisible(model) {
    model.updateMatrixWorld();
    const modelPosition = model.getWorldPosition(new THREE.Vector3());
    matrix.multiplyMatrices(camera.projectionMatrix, camera.matrixWorldInverse);
    frustum.setFromProjectionMatrix(matrix);
    return frustum.containsPoint(modelPosition);
}

function updateSoundBasedOnVisibility() {
    models.forEach((model, index) => {
        const sound = sounds[index];
        if (checkIfModelIsVisible(model)) {
            sound.setVolume(1);
        } else {
            sound.setVolume(0);
        }
    });
}


soundballConfigs.forEach((config, index) => {
    const group = new THREE.Group();
    
   
    const sound = new THREE.PositionalAudio(listener);
    audioLoader.load(config.soundPath, buffer => {
        sound.setBuffer(buffer)
            .setLoop(true)
            .setRefDistance(2)
            .setMaxDistance(10)
            .setDirectionalCone(60, 120, 0.1)
            .setVolume(1)
            .play();
        analyzers.push(new THREE.AudioAnalyser(sound, 64));
    });
    sounds.push(sound);

    
    const sphere = new THREE.Mesh(
        new THREE.SphereGeometry(0.5, 32, 32),
        new THREE.MeshBasicMaterial(config.material)
    );
    sphere.name = `${config.name}_sphere`;
    sphere.position.set(0, 0, 0);

    sphere.add(sound);
    


const audioHelper = new PositionalAudioHelper(sound);
sphere.add(audioHelper);


if (audioHelper.material && audioHelper.material.color) {
    audioHelper.material.color.set(0xff0000); 
} else if (Array.isArray(audioHelper.material)) {
   
    audioHelper.material.forEach(mat => {
        if (mat.color) mat.color.set(0xff0000); 
    });
} else {
    console.warn('PositionalAudioHelper material not accessible or does not support color changes.');
}

    

    
    if (config.hasModel) {
        loader.load(config.modelPath, gltf => {
            const model = gltf.scene;

            model.traverse((child) => {
                if (child.isMesh) {
                    const color = new THREE.Color(config.material.color);
                    const particles = createParticlesFromGeometry(child.geometry, color);
                    child.parent.add(particles);
                    child.parent.remove(child);
                }
            });

            model.scale.set(1, 1, 1);
            model.position.set(0, 0, 0);
            model.lookAt(centerPosition);
            model.name = `${config.name}_model`;

            group.add(model);
        });
    }

    group.add(sphere);
    group.position.copy(config.position);
    group.name = config.name;
    group.lookAt(centerPosition);

    scene.add(group);
    models.push(group);
});

const torusGeometry = new THREE.TorusGeometry(5.7, 3, 100, 150);
const torusMaterial = new THREE.ShaderMaterial({
    vertexShader: `
        varying vec3 vNormal;
        varying vec3 vPosition;

        void main() {
            vNormal = normalize(normalMatrix * normal);
            vPosition = (modelViewMatrix * vec4(position, 1.0)).xyz;
            gl_Position = projectionMatrix * modelViewMatrix * vec4(position, 1.0);
        }
    `,
    fragmentShader: `
        varying vec3 vNormal;
        varying vec3 vPosition;

        void main() {
            vec3 viewDir = normalize(-vPosition);
            vec3 lightPos = vec3(10.0, 10.0, 10.0);
            vec3 lightDir = normalize(lightPos - vPosition);
            vec3 reflectDir = reflect(-lightDir, vNormal);

            // Blackish color
            vec3 baseColor = vec3(0.001, 0.001, 0.001); // Dark color, close to black
            vec3 ambient = 0.1 * baseColor;

            float diffuseStrength = max(dot(vNormal, lightDir), 0.0);
            vec3 diffuse = diffuseStrength * baseColor;

            float specularStrength = pow(max(dot(viewDir, reflectDir), 0.0), 64.0);
            vec3 specular = specularStrength * vec3(1, 1, 1); // Dimmer specular highlights

            vec3 finalColor = ambient + diffuse + specular;

            gl_FragColor = vec4(finalColor, 1.0);
        }
    `,
    uniforms: {}
});

const torus = new THREE.Mesh(torusGeometry, torusMaterial);
scene.add(torus);

scene.add(new THREE.PointLight(0xffffff, 1, 100).position.set(10, 10, 10));
scene.add(new THREE.AmbientLight(0x404040));
scene.add(new THREE.DirectionalLight(0xffffff, 1).position.set(1, 5, 5).normalize());

camera.position.set(0.0001, 0, 0);
camera.lookAt(0, 0, 0);
camera.updateProjectionMatrix();

const sizes = {
    width: window.innerWidth,
    height: window.innerHeight
};

window.addEventListener('resize', () => {
    sizes.width = window.innerWidth;
    sizes.height = window.innerHeight;
    camera.aspect = sizes.width / sizes.height;
    camera.updateProjectionMatrix();
    renderer.setSize(sizes.width, sizes.height);
    renderer.setPixelRatio(Math.min(window.devicePixelRatio, 2));
});

const controls = new OrbitControls(camera, canvas);
controls.enabled = true;
controls.minDistance = 0.00001;
controls.maxDistance = 100;
controls.update();

const renderer = new THREE.WebGLRenderer({ canvas });
renderer.setSize(sizes.width, sizes.height);
renderer.setPixelRatio(Math.min(window.devicePixelRatio, 2));

const clock = new THREE.Clock();

function rotateCamera(elapsedTime) {
    const rotationSpeed = 0.01;
    camera.rotateX(rotationSpeed * 0.01);
}

const tick = () => {
    const elapsedTime = clock.getElapsedTime();

    models.forEach((model, index) => {
        const analyser = analyzers[index];
        if (analyser) {
            const frequencyData = analyser.getFrequencyData();
            const scale = 1 + (frequencyData.reduce((sum, value) => sum + value, 0) / frequencyData.length / 256) * 0.5;
            model.scale.set(scale, scale, scale);
        }
    });

    updateSoundBasedOnVisibility();

    rotateCamera(elapsedTime);
    renderer.render(scene, camera);
    window.requestAnimationFrame(tick);
};

tick();